import { MantineThemeOverride } from "@mantine/core";

export const theme: MantineThemeOverride = {
  fontFamily: "DanteSansTextW, sans-serif",
  fontSizes: {
    md: "calc(.875rem * 1)",
  },
  defaultRadius: "md",
  components: {
    Flex: {
      defaultProps: {
        mt: "lg",
      },
    },
    Title: {
      defaultProps: {
        order: 2,
      },
    },
    Table: {
      defaultProps: {
        verticalSpacing: "xs",
      },
    },
    Autocomplete: {
      defaultProps: {
        clearable: true,
      },
      styles: (theme) => ({
        section: { fontSize: 11 },
        error: { fontSize: theme.fontSizes.sm },
      }),
    },
    Select: {
      defaultProps: {
        clearable: true,
      },
      styles: (theme) => ({
        section: { fontSize: 11 },
        error: { fontSize: theme.fontSizes.sm },
      }),
    },
    Textarea: {
      defaultProps: {
        rightSectionPointerEvents: "none",
        rightSectionWidth: 70,
        resize: "vertical",
      },
      styles: (theme) => ({
        section: { fontSize: 11 },
        error: { fontSize: theme.fontSizes.sm },
      }),
    },
    TextInput: {
      defaultProps: {
        rightSectionPointerEvents: "none",
        rightSectionWidth: 70,
      },
      styles: (theme) => ({
        section: { fontSize: 11 },
        error: { fontSize: theme.fontSizes.sm },
      }),
    },
    NativeSelect: {
      styles: (theme) => ({
        input: {
          color: theme.colors.gray[7],
        },
        error: { fontSize: theme.fontSizes.sm },
      }),
    },
    Switch: {
      defaultProps: (theme) => ({
        color: theme.colors.teal[7],
        size: "lg",
      }),
    },
    Modal: {
      styles: (theme) => ({
        title: {
          fontSize: theme.fontSizes.lg,
        },
      }),
    },
    Alert: {
      defaultProps: {
        variant: "light",
        mb: "lg",
      },
    },
    Card: {
      defaultProps: {
        shadow: "sm",
        padding: "lg",
        radius: "md",
        withBorder: true,
      },
    },
  },
};

// mantine not being able to catch Transition defaultProps
export const TransitionDefault = {
  duration: 100,
  enterDelay: 300,
  timingFunction: "ease-in-out",
};
