import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import { Toaster } from "sonner";
import Header from "./Header";
import { autoLogoff } from "../../hooks/useWebSocket";
import { Box, Container } from "@mantine/core";

const MainLayout: React.FC = () => {
  autoLogoff();

  return (
    <>
      <Header />
      <main>
        <Box style={{ height: "calc(100vh - 77px" }} p="md">
          <Container size={1440} py="lg" mx="auto">
            <Outlet />
          </Container>
        </Box>
      </main>
      <Toaster richColors position="top-right" />
      <Footer />
    </>
  );
};

export default MainLayout;
