import { Anchor, Box, Group, Stack } from "@mantine/core";

const BannerBottom: React.FC = () => {
  return (
    <Stack>
      <Box>
        <Box>
          <b>Please note:</b> Firmware submissions will be visible to all users
          of the Dante Updater software. Please only submit production firmware
          files, or notifications for production firmware.
        </Box>
        <Box>
          If you have any questions regarding Dante Updater, the firmware
          upload process or to remove previously submitted firmware, please
          contact{" "}
          <Anchor href="mailto:oem-support@audinate.com">
            oem-support@audinate.com
          </Anchor>
        </Box>
      </Box>
      <Group gap="md">
        <b>More Information:</b>{" "}
        <Anchor
          href="https://www.getdante.com/support/faq/?_ct_featured_product=dante-updater"
          target="_blank"
          rel="noopener noreferrer"
        >
          Dante Updater FAQs
        </Anchor>
        <Anchor
          href="https://dev.audinate.com/GA/dante-updater/userguide/webhelp/?_gl=1*18ozu8v*_ga*MTk4MjY5NDMxOC4xNzE3NTQxNzE1*_ga_BQJVS521EK*MTcyNDI4OTA3Ni4yNi4xLjE3MjQyOTEzMDUuMC4wLjA."
          target="_blank"
          rel="noopener noreferrer"
        >
          Dante Updater User Guide
        </Anchor>
      </Group>
    </Stack>
  );
};

export default BannerBottom;
