import { Flex, Box } from "@mantine/core";

import { ROLES } from "../../constants/roles";
import { RoleProps } from "types/constants";

const BannerBottom: React.FC = () => {
  return (
    <Flex direction="column" gap="md">
      {ROLES.map((role: RoleProps) => (
        <Box key={role.value}>
          <b>{role.name}:</b> {role.description}
        </Box>
      ))}
    </Flex>
  );
};

export default BannerBottom;
