import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";

import { usePermission } from "../hooks/usePermission";

export const PrivateRoutes = () => {
  const [accessToken, setAccessToken] = useState(null);
  const {
    loginWithRedirect,
    isAuthenticated,
    user,
    getAccessTokenSilently,
    isLoading,
    error,
    logout,
  } = useAuth0();
  const { data: roleData, refetch: roleRefetch } = usePermission();

  // get auth0 access token
  useEffect(() => {
    if (
      !Cookies.get("user_permission") ||
      !Cookies.get("aId") ||
      !Cookies.get("access_token")
    ) {
      if (!isLoading && isAuthenticated) {
        const getUserMetadata = async () => {
          try {
            const accessToken = await getAccessTokenSilently({
              authorizationParams: {
                scope: "read:current_user",
              },
            });

            Cookies.set("access_token", accessToken);
            setAccessToken(accessToken);
          } catch (e) {
            console.log(e.message);
          }
        };

        if (!accessToken) {
          getUserMetadata();
        }
      }
    }
  }, [getAccessTokenSilently, user?.sub]);

  // get user role
  useEffect(() => {
    // refetch user role on first load
    if (Cookies.get("access_token")) {
      roleRefetch();
    }
  }, [accessToken]);

  // save user role as cookie
  useEffect(() => {
    if (roleData) {
      if (roleData.status == "failed") {
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      } else {
        // logout if permissions differ from existing
        if (
          Cookies.get("user_permission") &&
          Cookies.get("user_permission") !== roleData.type?.join("-")
        ) {
          logout({
            logoutParams: {
              returnTo: `${window.location.origin}/auto-logout`,
            },
          });
        }

        Cookies.set("user_permission", roleData.type?.join("-"));
        Cookies.set("user_name", roleData.userName);
        Cookies.set("manufacturer", roleData.manufacturer);
        setAccessToken(null);
        Cookies.set("aId", roleData.aId);
      }
    }
  }, [roleData]);

  if (error) {
    return <Navigate to="/restricted" replace={true} />;
  }

  if (!isLoading && !isAuthenticated) {
    Cookies.remove("user_permission");
    Cookies.remove("aId");
    Cookies.remove("user_name");
    Cookies.remove("manufacturer");
    Cookies.remove("access_token");
    Cookies.set("prevLocation", window.location.pathname);
    loginWithRedirect();
  }

  if (
    isAuthenticated &&
    (Cookies.get("user_permission") || Cookies.get("user_permission") == "") &&
    Cookies.get("aId") &&
    Cookies.get("access_token")
  ) {
    return <Outlet />;
  }
};
