import { useEffect, useState } from "react";
import {
  HiOutlineFolder,
  HiOutlineFolderOpen,
  HiChevronRight,
  HiChevronDown,
  HiOutlineFolderRemove,
} from "react-icons/hi";
import classes from "./TreeView.module.css";
import { Box, Group, Stack, Text } from "@mantine/core";
import { TreeConfigProps, TreeNodeProps, TreeViewProps } from "types/ui";

const CONFIG_DEFAULT = {
  id: "id",
  parent: "parent",
  children: "children",
  toReturn: [],
}

const highlightNodeText = (text: string, searchString: string): string | (string | JSX.Element)[] => {
  if (!text || !searchString) return text;

  const escapedRegexString = new RegExp(
    `(${searchString.replace(/([.*+?^${}()|[\]\\/\\])/g, "\\$1")})`,
    "gi"
  );
  const parts = text.split(escapedRegexString);

  return parts.map((part: string, index: number) => {
    if (part.toLowerCase() === searchString.toLowerCase()) {
      return (
        <Text
          component="span"
          key={index}
          c="blue"
          bg="white"
          style={{ borderRadius: "5px" }}
        >
          <b>{part}</b>
        </Text>
      );
    }
    return part;
  });
};

const filterNode = (
  data: object,
  searchString: string,
  config: TreeConfigProps
): object | null => {
  if (!data || typeof data !== "object") return null;

  const filteredChildren = (data[config.children] || [])
    .map((child: Record<string, any>) => filterNode(child, searchString, config))
    .filter(Boolean);

  if (data[config.parent]?.toLowerCase().includes(searchString.toLowerCase())) {
    const highlightedName = highlightNodeText(
      data[config.parent],
      searchString
    );

    return {
      ...data,
      [config.parent]: highlightedName,
      [config.children]: filteredChildren,
    };
  }

  if (filteredChildren.length > 0) {
    return {
      ...data,
      [config.children]: filteredChildren,
    };
  }

  return null;
};

const TreeNode: React.FC<TreeNodeProps> = ({
  config = CONFIG_DEFAULT,
  node = {},
  activeNode = null,
  setActiveNode = () => { },
  onSelectNode = () => { },
  expanded = false,
  expandAll = false,
}) => {
  const [isOpen, setIsOpen] = useState(expanded);

  useEffect(() => {
    setIsOpen(expanded || expandAll);
  }, [expandAll]);

  const handleToggle = (): void => {
    setIsOpen(!isOpen);
  };

  const handleNodeClick = (): void => {
    setActiveNode(node[config.id]);
    onSelectNode(config.toReturn.map((key: string) => node[key]));
  };

  return (
    <Stack gap={0}>
      <Group
        gap={0}
        px="xs"
        style={{
          cursor: "pointer",
          borderRadius: "5px",
          backgroundColor:
            activeNode === node[config.id] ? "lightgray" : "inherit",
        }}
        onClick={() => {
          handleToggle();
          handleNodeClick();
        }}
      >
        <Text component="span">
          {isOpen && node[config.children]?.length > 0 ? (
            <HiOutlineFolderOpen />
          ) : (
            <HiOutlineFolder />
          )}
        </Text>
        <Text
          component="span"
          mr={node[config.children]?.length > 0 ? "" : "sm"}
        >
          {node[config.children]?.length > 0 && isOpen && <HiChevronDown />}
          {node[config.children]?.length > 0 && !isOpen && <HiChevronRight />}
        </Text>
        <Box>{node[config.parent]}</Box>
      </Group>
      <Box
        className={`${classes.treeNode} ${isOpen ? classes.treeNodeExpanded : classes.treeNodeCollapsed
          }`}
      >
        {isOpen && node[config.children] && (
          <Box pl="sm" ml="sm" style={{ borderLeft: "1px solid lightgray" }}>
            {node[config.children]?.map((childNode: Record<string, any>) => (
              <TreeNode
                key={childNode[config.id]}
                config={config}
                node={childNode}
                activeNode={activeNode}
                setActiveNode={setActiveNode}
                onSelectNode={onSelectNode}
                expandAll={expandAll}
              />
            ))}
          </Box>
        )}
      </Box>
    </Stack>
  );
};

const TreeView: React.FC<TreeViewProps> = ({
  data = {},
  dataFilter = "",
  config = CONFIG_DEFAULT,
  onSelectNode = () => { },
  expandFirst = false,
}) => {
  const [activeNode, setActiveNode] = useState<string | null>();
  const [items, setItems] = useState<object>(data);

  useEffect(() => {
    if (expandFirst) {
      setActiveNode(data[config.id]);
      onSelectNode(config.toReturn.map((key: string) => data[key]));
    }
  }, []);

  useEffect(() => {
    setItems(filterNode(data, dataFilter, config));
  }, [dataFilter]);

  return (
    <Box>
      {items &&
        [items]?.length > 0 &&
        [items].map((node: Record<string, any>) => (
          <TreeNode
            key={node[config.id]}
            config={config}
            node={node}
            activeNode={activeNode}
            setActiveNode={setActiveNode}
            onSelectNode={onSelectNode}
            expanded={expandFirst}
            expandAll={dataFilter !== ""}
          />
        ))}
      {!items && (
        <Group px="xs" gap="sm">
          <HiOutlineFolderRemove />
          No result found.
        </Group>
      )}
    </Box>
  );
};

export default TreeView;
