import { isData } from "../utils/HookResponse";
import { useFetchData } from "../utils/ReactQueryHooks";
import { STALE } from "../constants/stale";

export const useDeviceCodes = () =>
  useFetchData({
    key: "deviceCodes",
    url: "device-codes",
    options: {
      select: (data) => isData(data),
      staleTime: STALE.MEDIUM,
    },
  });
