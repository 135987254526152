import { DateTime } from "luxon";
import { ColumnDef } from "@tanstack/react-table";

import Table from "components/ui/Table/Table";

import { FirmwareColumnData, TableSubmissionsProps } from "types/module/Firmware";

const TableSubmissions: React.FC<TableSubmissionsProps> = ({
  data,
  isLoading,
}) => {
  const columns: ColumnDef<FirmwareColumnData>[] = [
    {
      header: "File ID",
      accessorKey: "id",
    },
    {
      header: "Upload Date",
      accessorKey: "uploadedDate",
      cell: (info) =>
        DateTime.fromISO(info.getValue() as string).toLocaleString(DateTime.DATE_MED),
    },
    {
      header: "Manufacturer",
      accessorKey: "manufacture",
    },
    {
      header: "Username",
      accessorKey: "userName",
    },
    {
      header: "Dante Product Family",
      accessorKey: "product",
    },
    {
      header: "Dante Firmware Version",
      accessorKey: "danteSwVersion",
    },
    {
      header: "Product Model Name",
      accessorKey: "modelName",
    },
    {
      header: "Product Version",
      accessorKey: "modelVersion",
    },
    {
      header: "Status",
      accessorKey: "status",
    },
    {
      header: "Date Actioned",
      accessorKey: "actionDate",
      cell: (info) =>
        DateTime.fromISO(info.getValue() as string).toLocaleString(DateTime.DATE_MED),
    },
  ];
  return (
    <Table data={data} columns={columns} isLoading={isLoading} />
  );
};

export default TableSubmissions;
