export const ROLES = [
  {
    value: "Admin",
    name: "Admin",
    description:
      "Power user. Ability to see everything in the portal, upload firmware, login to Module Config Tool and manage users; update pool balance notification threshold, add/remove contacts to receive pool balance notifications.",
  },
  {
    value: "Firmware Manager",
    name: "Firmware Manager",
    description:
      "Ability to create and view self-requested support cases; use Firmware Manager; login to Module Config Tool; view license pool balances, technical documentation (on OEM Home), Your Releases, News & Events, Resources and marketing materials.",
  },
  {
    value: "Engineering",
    name: "Engineering",
    description:
      "Ability to create and view self-requested support cases; view license pool balances, technical documentation (on OEM Home), Your Releases, News & Events, Resources and marketing materials.",
  },
  {
    value: "License Manager",
    name: "License Manager",
    description:
      "Ability to create and view self-requested support cases; view technical documentation (on OEM Home), Your Releases, News & Events, Resources and marketing materials; view license pool balances, update pool balance notification threshold, add/remove contacts to receive pool balance notifications.",
  },
  {
    value: "Marketing",
    name: "Marketing",
    description:
      "Ability to create and view self-requested support cases; view News & Events, Resources, marketing materials and submit marketing-related web forms.",
  },
];
