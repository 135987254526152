import { ChangeEvent, useState } from "react";
import { useLicenses } from "hooks/useLicense";

import { HierarchyProps } from "types/module/License";

import Skeleton from "components/ui/Preloader/Skeleton";
import TreeView from "components/ui/TreeView/TreeView";
import HeaderTitle from "components/ui/Header/HeaderTitle";

import { Input } from "@mantine/core";

const Hierarchy: React.FC<HierarchyProps> = ({ handleSelectAccount }) => {
	const { data: licenseData, isLoading: isLicenseDataLoading } = useLicenses();
	const [accountFilter, setAccountFilter] = useState<string>("");

	const handleAccountSearch = (e: ChangeEvent<HTMLInputElement>): void => {
		setAccountFilter(e.target.value);
	};

	return (
		<>
			<HeaderTitle title="Accounts">
				{!(licenseData?.length == 0 || isLicenseDataLoading) && <Input
					hidden={licenseData?.length == 0 || isLicenseDataLoading}
					placeholder="Search Accounts..."
					value={accountFilter}
					onChange={handleAccountSearch}
				/>}
			</HeaderTitle>
			{isLicenseDataLoading ? (
				<Skeleton />
			) : !isLicenseDataLoading && licenseData ? (
				<TreeView
					data={licenseData}
					dataFilter={accountFilter}
					config={{
						id: "accountId",
						parent: "name",
						children: "accounts",
						toReturn: ["isTopLevelAccount", "licenses"],
					}}
					onSelectNode={handleSelectAccount}
					expandFirst={true}
				/>
			) : (
				""
			)}
		</>
	);
};

export default Hierarchy;
