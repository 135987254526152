import { ChangeEvent, useEffect, useState } from "react";
import { useLicensePools } from "hooks/useLicense";

import Hierarchy from "./Hierarchy";
import TableLicenses from "./TableLicenses";
import ViewLicense from "./ViewLicense";

import { FormData, Serial, SerialsData } from "types/module/License";
import HeaderTitle from "components/ui/Header/HeaderTitle";
import { Button, Grid, Input, Modal } from "@mantine/core";
import ModalButtons from "components/ui/Modal/ModalButtons";

import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import toLower from "lodash/toLower";

const SERIAL_DEFAULT: Serial = {
	status: null,
	serialNumber: null,
	contactName: null,
	accountName: null,
	pool_balance_threshold: null,
	notification_contacts: null,
};

const License: React.FC = () => {
	document.title = "Licenses";
	const [serials, setSerials] = useState<Serial[]>([]);
	const [licenseFilter, setLicenseFilter] = useState<string>("");
	const [serial, setSerial] = useState<Serial>(SERIAL_DEFAULT);
	const [isOpenLicenseModal, setIsOpenLicenseModal] = useState<boolean>(false);
	const [isSerialsEditable, setIsSerialsEditable] = useState<boolean>(false);
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
	const [showConfirmationModal, setShowConfirmationModal] =
		useState<boolean>(false);

	const {
		data: serialsData,
		isFetching: isSerialsDataLoading,
		refetch: serialsDataRefetch,
	} = useLicensePools(serials.map((item: Serial) => item.serialNumber));

	const handleSerialsData = (data: SerialsData[]) => {
		if (isEmpty(data)) return [];

		return filter(data, (item) => {
			if (!licenseFilter) return true;

			const lowerCaseFilter = toLower(licenseFilter);

			return (
				toLower(item.name).includes(lowerCaseFilter) ||
				toLower(item.uuid).includes(lowerCaseFilter)
			);
		});
	};

	const handleCloseModal = () => {
		if (hasUnsavedChanges) {
			setShowConfirmationModal(true);
			return;
		}
		setIsOpenLicenseModal(false);
	};

	const handleSelectAccount = (data: [boolean, Serial[]]) => {
		if (data.length === 2 && data.every((item) => item !== undefined)) {
			setSerials(data[1]);
			setIsSerialsEditable(data[0]);
		}
	};

	useEffect(() => {
		if (serials.length > 0) {
			serialsDataRefetch();
		}
	}, [serials]);

	const handleFormChange = (data: FormData) => {
		const isModified =
			data?.threshold != serial?.pool_balance_threshold ||
			(data?.contacts?.join(";") || null) !== (serial?.notification_contacts || null);
		setHasUnsavedChanges(isModified);
	};

	const handleLicenseSearch = (e: ChangeEvent<HTMLInputElement>): void => {
		setLicenseFilter(e.target.value);
	};

	return (
		<>
			<Grid>
				<Grid.Col span={{ md: 3 }}>
					<Hierarchy handleSelectAccount={handleSelectAccount} />
				</Grid.Col>
				<Grid.Col span={{ md: 9 }}>
					<HeaderTitle title="Licenses">
						{serials?.length > 0 && (
							<>
								<Input
									placeholder="Search Licenses..."
									value={licenseFilter}
									onChange={handleLicenseSearch}
								/>
								<Button
									color="orange"
									loading={isSerialsDataLoading ? true : false}
									onClick={() => {
										setLicenseFilter("");
										serialsDataRefetch();
									}}
								>
									Refresh License Pool Balances
								</Button>
							</>
						)}
					</HeaderTitle>
					<TableLicenses
						serialsData={
							serials.length > 0 ? handleSerialsData(serialsData) : []
						}
						isSerialsDataLoading={isSerialsDataLoading}
						handleSelectLicense={(serial: string) => {
							setSerial(serials.find((item) => item.serialNumber == serial));
							setIsOpenLicenseModal(true);
						}}
					/>
				</Grid.Col>
			</Grid>
			<Modal
				size="70%"
				title="View License"
				opened={isOpenLicenseModal}
				onClose={handleCloseModal}
			>
				{serial?.serialNumber && (
					<ViewLicense
						serial={serial}
						setSerials={setSerials}
						isEditable={isSerialsEditable}
						isOpenLicenseModal={isOpenLicenseModal}
						handleFormChange={handleFormChange}
						setHasUnsavedChanges={setHasUnsavedChanges}
					/>
				)}
			</Modal>
			<Modal
				opened={showConfirmationModal}
				title="Unsaved Changes"
				onClose={() => setShowConfirmationModal(false)}
				centered
			>
				You have unsaved changes that will be lost if you close this window.
				Do you wish to proceed?
				<ModalButtons>
					<Button variant="default" onClick={() => setShowConfirmationModal(false)}>
						No
					</Button>
					<Button
						color="orange"
						onClick={() => {
							setShowConfirmationModal(false);
							setIsOpenLicenseModal(false);
						}}
					>
						Yes
					</Button>
				</ModalButtons>
			</Modal>
		</>
	);
};

export default License;
