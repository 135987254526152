import { useCallback, useEffect, useState } from "react";
import { DateTime, DurationLike } from "luxon";
import orderBy from "lodash/orderBy";
import { ColumnDef } from "@tanstack/react-table";

import { Allocation, LicenseFilterData, PeriodOptions, TableBoundProps } from "types/module/License";

import Skeleton from "components/ui/Preloader/Skeleton";
import Table from "components/ui/Table/Table";
import {
  Button,
  Divider,
  Flex,
  Group,
  Pill,
  Select,
  SelectProps,
  Space,
  TextInput,
  Title
} from "@mantine/core";
import { FaInfoCircle } from "react-icons/fa";

const FILTER_DEFAULT: LicenseFilterData = {
  in: {
    from: "",
    to: "",
  },
  out: {
    from: "",
    to: "",
  },
};

const periodOptions: PeriodOptions = [
  { value: "all", label: "All time" },
  { value: "last30", label: "Last 30 days" },
  { value: "last90", label: "Last 90 days" },
  { value: "last12Months", label: "Last 12 months" },
  { value: "last3Years", label: "Last 3 years" },
  {
    value: "custom",
    label: "Custom",
    icon: <FaInfoCircle title="Custom date range" />,
  },
];

const TableBounds: React.FC<TableBoundProps> = ({ serialData, isSerialDataLoading, isOpen }) => {
  const columnsInOut: ColumnDef<Allocation>[] = [
    {
      header: "Transaction Date",
      accessorKey: "created.at",
      cell: (info) =>
        DateTime.fromISO(info.getValue<string>()).toLocaleString(DateTime.DATE_MED),
    },
    {
      header: "Quantity",
      accessorKey: "quantity",
      cell: (item) => item.getValue<string>() || "1",
    },
  ];

  const [dateFilter, setDateFilter] =
    useState<LicenseFilterData>(FILTER_DEFAULT);
  const [selectedInPeriod, setSelectedInPeriod] = useState<string>("all");
  const [selectedOutPeriod, setSelectedOutPeriod] = useState<string>("all");

  useEffect(() => {
    if (isOpen) {
      setDateFilter(FILTER_DEFAULT);
      setSelectedInPeriod("all");
      setSelectedOutPeriod("all");
    }
  }, [isOpen]);

  const calculateDateRange = (period: string, type: string, prevDateFilter: LicenseFilterData) => {
    const now = DateTime.now();

    const periodOffsets: Record<string, DurationLike> = {
      last30: { days: 30 },
      last90: { days: 90 },
      last12Months: { months: 12 },
      last3Years: { years: 3 },
    };

    if (period === "custom") {
      return { fromDate: prevDateFilter[type].from, toDate: prevDateFilter[type].to };
    }

    const offset = periodOffsets[period];
    if (!offset) return { fromDate: "", toDate: "" };

    return {
      fromDate: now.minus(offset).toISODate(),
      toDate: now.toISODate(),
    };
  };

  const handleInOutData = (data: Allocation[], type: string) => {
    const mergeByDate = (data: Allocation[]) => {
      const groupedData = {};

      data.forEach((item: Allocation) => {
        const date = item.created.at.split("T")[0];
        const quantity = item.quantity || 1;

        if (!groupedData[date]) groupedData[date] = 0;

        groupedData[date] += quantity;
      });

      const mergedData = Object.keys(groupedData).map((date) => ({
        created: { at: date + "T00:00:00.000Z" },
        quantity: groupedData[date],
      }));

      return mergedData;
    };

    const filteredTableData = mergeByDate(data).filter((item: Allocation) => {
      if (
        (dateFilter[type].from !== "" &&
          item.created.at.slice(0, 10) < dateFilter[type].from) ||
        (dateFilter[type].to !== "" &&
          item.created.at.slice(0, 10) > dateFilter[type].to)
      ) {
        return false;
      }
      return true;
    });

    const sortedTableData = orderBy(
      filteredTableData,
      ["created.at"],
      ["desc"],
    );
    return sortedTableData;
  };

  const handlePeriodChange = (
    selectedOption: string,
    type: string
  ) => {
    if (type === "in") {
      setSelectedInPeriod(selectedOption);
    } else {
      setSelectedOutPeriod(selectedOption);
    }

    setDateFilter((prev) => {
      const { fromDate, toDate } = calculateDateRange(selectedOption, type, prev);

      return {
        ...prev,
        [type]: { from: fromDate, to: toDate },
      };
    });
  };

  const handleFilterReset = useCallback((type: string) => {
    setDateFilter((prev) => ({
      ...prev,
      [type]: { from: "", to: "" },
    }));

    const resetPeriod =
      type === "in" ? setSelectedInPeriod : setSelectedOutPeriod;
    resetPeriod("all");
  }, []);

  const getBoundSum = (data: Allocation[]) => {
    return data.reduce((sum, item) => sum + (item.quantity || 1), 0);
  };

  const renderPeriodOption: SelectProps['renderOption'] = ({ option }) => (
    <Group flex="1" gap="xs">
      {option.label}
      {periodOptions.find((item) => item.value === option.value)?.icon}
    </Group>
  );

  const renderLicenseBound = (type: string) => {
    const label = type === "in" ? "Added/Purchased" : "Activation/Used";
    const dataKey =
      type === "in" ? "inboundAllocations" : "outboundAllocations";
    const selectedPeriod = type === "in" ? selectedInPeriod : selectedOutPeriod;

    return (
      <>
        <Flex align="center" gap="xs" mt={0}>
          <Flex flex={1} align="center" mt={0}>
            <Title order={5}><b>{label}</b></Title>
            <Pill bg="gray" c="white" ml="xs">{getBoundSum(handleInOutData(serialData[dataKey], type))}</Pill>
          </Flex>
          {selectedPeriod === "custom" && (
            <>
              <TextInput
                key={`${type}From`}
                type="date"
                value={dateFilter[type].from}
                onChange={(e) =>
                  setDateFilter((prev) => ({
                    ...prev,
                    [type]: { ...prev[type], from: e.target.value },
                  }))
                }
              />
              -
              <TextInput
                key={`${type}To`}
                type="date"
                value={dateFilter[type].to}
                onChange={(e) =>
                  setDateFilter((prev) => ({
                    ...prev,
                    [type]: { ...prev[type], to: e.target.value },
                  }))
                }
              />
            </>
          )}
          <Select
            key="period"
            value={periodOptions.find(
              (option) => option.value === selectedPeriod,
            )?.value}
            data={periodOptions}
            onChange={(selectedOption) => handlePeriodChange(selectedOption, type)}
            renderOption={renderPeriodOption}
            clearable={false}
            w={150}
          />
          <Button
            color="orange"
            onClick={() => handleFilterReset(type)}
          >
            Clear
          </Button>
        </Flex>
        <Table
          data={handleInOutData(serialData[dataKey], type)}
          columns={columnsInOut}
          pageSize={5}
        />
      </>
    );
  };

  return (
    <>
      {isSerialDataLoading && (
        <>
          <Skeleton />
          <Space h="md" />
          <Skeleton />
        </>
      )}
      {!isSerialDataLoading && serialData && (
        <div>
          {renderLicenseBound("in")}
          <Divider my="md" />
          {renderLicenseBound("out")}
        </div>
      )}
    </>
  );
};

export default TableBounds;
