import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import isEmpty from "lodash/isEmpty";

import { FaQuestionCircle } from "react-icons/fa";
import Skeleton from "components/ui/Preloader/Skeleton";
import {
	Button,
	Divider,
	Fieldset,
	Flex,
	Grid,
	Group,
	NumberInput,
	Pill,
	PillsInput,
	Stack,
	TagsInput,
	Text,
	TextInput,
	Tooltip
} from "@mantine/core";
import TableBounds from "./TableBounds";
import ModalButtons from "components/ui/Modal/ModalButtons";
import { useForm } from "@mantine/form";

import { FormData, ViewLicenseProps } from "types/module/License";
import { useLicenseNotificationUpdate, useLicensePool } from "hooks/useLicense";
import { MSG } from "constants/msg";

const FORM_DEFAULT: FormData = {
	threshold: null,
	contacts: null,
};

const ViewLicense: React.FC<ViewLicenseProps> = ({
	serial,
	setSerials,
	isEditable,
	isOpenLicenseModal,
	handleFormChange,
	setHasUnsavedChanges,
}) => {
	const form = useForm({
		initialValues: {
			threshold: serial.pool_balance_threshold,
			contacts: (serial.notification_contacts &&
				serial.notification_contacts.split(";")) || [],
		},
		validate: {
			threshold: (value) => {
				if (value === 0) return null;
				if (!value) return MSG.VALIDATE.REQUIRED('Notification Threshold');
				return /^\d+$/.test(value.toString()) ? null : "Only whole numbers are allowed";
			},
			contacts: (value) => {
				if (!value || value.length === 0) return null;
				return value.every((tag) =>
					/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(tag),
				) ? null : "All contacts must be valid email addresses";
			},
		},
	});

	const [formData, setFormData] = useState<FormData>(FORM_DEFAULT);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const {
		data: serialData,
		isFetching: isSerialDataLoading,
		refetch: serialDataRefetch,
	} = useLicensePool(serial.serialNumber);

	const {
		mutate: notificationUpdateMutate,
		data: notificationUpdateData,
		isSuccess: notificationUpdateSuccess,
		isError: notificationUpdateError,
	} = useLicenseNotificationUpdate();

	const handleResetForm = () => {
		form.setFieldValue("threshold", serial.pool_balance_threshold);
		form.setFieldValue("contacts", (serial.notification_contacts &&
			serial.notification_contacts.split(";")) || []);
		setHasUnsavedChanges(false)
	};

	const handleNotificationSubmit = (data: FormData) => {
		setIsSubmitting(true);
		setFormData({
			threshold: data.threshold,
			contacts: data.contacts,
		});

		// @ts-ignore temporary for further migration
		notificationUpdateMutate({
			serial: serial.serialNumber,
			contacts: data.contacts,
			threshold: data.threshold,
			prevThreshold: serial?.pool_balance_threshold,
			userName: Cookies?.get("user_name"),
		});

		setHasUnsavedChanges(false);
	};

	useEffect(() => {
		if (!isEmpty(serial)) {
			serialDataRefetch();
		}
	}, [serial]);

	useEffect(() => {
		handleResetForm();
		setHasUnsavedChanges(false);
		if (!isOpenLicenseModal) {
			form.reset();
		}
	}, [serial, isOpenLicenseModal]);

	useEffect(() => {
		if (notificationUpdateSuccess || notificationUpdateError) {
			if (
				notificationUpdateSuccess &&
				notificationUpdateData.data?.status == "success"
			) {
				setSerials((prev) =>
					prev.map((item) => {
						if (item.serialNumber == serial.serialNumber) {
							item.pool_balance_threshold = formData.threshold;
							item.notification_contacts = formData.contacts.join(";");
						}
						return item;
					}),
				);
			} else {
				setFormData(FORM_DEFAULT);
			}

			setIsSubmitting(false);
		}
	}, [notificationUpdateSuccess, notificationUpdateError]);

	return (
		<Grid>
			<Grid.Col span={{ md: 4 }}>
				{isSerialDataLoading && <Skeleton />}
				{!isSerialDataLoading && serialData && serialData.pool && (
					<Stack gap="sm">
						<TextInput
							variant="unstyled"
							label="Account"
							value={serial.accountName}
							readOnly
						/>
						<TextInput
							variant="unstyled"
							label="License Name"
							value={serialData.pool.name}
							readOnly
						/>
						<TextInput
							variant="unstyled"
							label="Contact"
							value={serial.contactName}
							readOnly
						/>
						<TextInput
							variant="unstyled"
							label="Status"
							value={serial.status}
							readOnly
						/>
						<TextInput
							variant="unstyled"
							label="Pool ID"
							value={serialData.pool.uuid}
							readOnly
						/>
						<TextInput
							variant="unstyled"
							label="Audinate Product"
							value={serialData.pool.productSKU.sku}
							readOnly
						/>
						<TextInput
							variant="unstyled"
							label="Pool Balance"
							value={serialData.pool.balance}
							readOnly
						/>
						{(Cookies.get("user_permission")
							.split("-")
							.includes("licenseThresholdManagement") &&
							isEditable && (
								<form onSubmit={form.onSubmit((values) => handleNotificationSubmit(values))}>
									<Fieldset>
										<NumberInput
											key={form.key("threshold")}
											label={
												<Tooltip multiline w={400} label={MSG.HELP.NOTIFICATION_THRESHOLD}>
													<Flex justify="space-between" align="center" gap="xs" mt="0">
														Notification Threshold
														<FaQuestionCircle />
													</Flex>
												</Tooltip>
											}
											placeholder="Enter Notification Threshold"
											onChange={handleFormChange(form.values)}
											disabled={isSubmitting}
											min={0}
											step={1}
											my="md"
											{...form.getInputProps("threshold")}
										/>
										<TagsInput
											key={form.key("contacts")}
											label="Notification Contacts"
											placeholder="Enter an email"
											onChange={handleFormChange(form.values)}
											disabled={isSubmitting}
											{...form.getInputProps("contacts")}
										/>
										{form.values.contacts.length === 0 && (
											<Text size="xs" c="blue">
												If notification contacts are not provided, all
												notifications will be sent to the License Contact above.
											</Text>
										)}
										<ModalButtons>
											<Button type="submit" loading={isSubmitting}>Update</Button>
											<Button
												variant="default"
												onClick={handleResetForm}
												disabled={isSubmitting}
											>
												Cancel
											</Button>
										</ModalButtons>
									</Fieldset>
								</form>
							)) || (
								<>
									<TextInput
										variant="unstyled"
										label="Notification Threshold"
										value={serial.pool_balance_threshold || "-"}
										readOnly
									/>
									<PillsInput label="Notification Contacts">
										<Pill.Group>
											{(serial.notification_contacts &&
												serial.notification_contacts
													.split(";")
													.map((email, key) => (
														<Pill key={key}>{email}</Pill>
													))) ||
												""}
										</Pill.Group>
									</PillsInput>
								</>
							)}
					</Stack>
				)}
			</Grid.Col>
			<Grid.Col span={{ md: 8 }}>
				<Group>
					<Divider orientation="vertical" size="xs" />
					<Text component="div" style={{ flex: 1 }}>
						<TableBounds
							serialData={serialData}
							isSerialDataLoading={isSerialDataLoading}
							isOpen={isOpenLicenseModal}
						/>
					</Text>
				</Group>
			</Grid.Col>
		</Grid>
	);
};

export default ViewLicense;
