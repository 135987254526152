import { SerialsData, TableLicensesProps } from "types/module/License";

import Table from "components/ui/Table/Table";
import { Alert, Button } from "@mantine/core";
import { FaExclamationCircle } from "react-icons/fa";

const TableLicenses: React.FC<TableLicensesProps> = ({
	serialsData,
	isSerialsDataLoading,
	handleSelectLicense,
}) => {
	const columns = [
		{
			header: "License Name",
			accessorKey: "name",
		},
		{
			header: "Pool ID",
			accessorKey: "uuid",
		},
		{
			header: "Pool Balance",
			accessorKey: "balance",
		},
		{
			header: "Actions",
			cell: ({ row }: { row: { original: SerialsData } }) =>
				row.original.uuid && (
					<Button
						onClick={() => {
							if (row.original.uuid) {
								handleSelectLicense(row.original.uuid);
							}
						}}
						size="xs"
					>
						View
					</Button>
				),
		},
	];

	return (
		<>
			{serialsData.length === 0 && (
				<Alert color="yellow" icon={<FaExclamationCircle />}>
					If there&apos;s no balance displayed, you may have purchased a
					different type of license pool. Please contact our support team if
					you have any questions.
				</Alert>
			)}
			<Table
				data={
					serialsData.length > 0
						? serialsData
						: [{ name: "No license available." }]
				}
				columns={columns}
				isLoading={isSerialsDataLoading}
			/>
		</>
	);
};

export default TableLicenses;
