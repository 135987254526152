import { useEffect, useState } from "react";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
} from "@tanstack/react-table";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import Skeleton from "../Preloader/Skeleton";

import {
  Table as MtTable,
  Button,
  Text,
  ScrollArea,
  Flex,
  useMantineTheme,
} from "@mantine/core";
import { PaginationState, TableProps } from "types/ui";

const Table: React.FC<TableProps<any>> = ({
  isLoading = false,
  data = [],
  columns = [],
  tfoot = null,
  pageSize = 10,
}) => {
  const theme = useMantineTheme();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize,
  });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    autoResetPageIndex: false,
  });

  useEffect(() => {
    if (
      table.getState().pagination.pageIndex + 1 >
      table.getPageCount()
    ) {
      table.setPageIndex(table.getPageCount() - 1);
    }
  }, [data]);

  return (
    <Flex direction="column">
      <ScrollArea>
        <MtTable highlightOnHover>
          <MtTable.Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <MtTable.Tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => (
                  <MtTable.Th
                    key={header.id}
                    onClick={
                      columns[index]?.enableSorting === false ? () => { }
                        : header.column.getToggleSortingHandler()
                    }
                    bg={theme.colors.gray[1]}
                    style={{
                      width: `${header.getSize() !== 150
                        ? header.getSize() + "px"
                        : "auto"
                        }`,
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <Flex align="center" gap="xs" mt={0}>
                        <Text size="xs" fw="700">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Text>
                        {
                          (columns[index]?.enableSorting === false) || (
                            <>
                              {!header.column.getIsSorted() && <FaSort />}
                              {
                                { asc: <FaSortUp />, desc: <FaSortDown /> }[
                                header.column.getIsSorted() as "asc" | "desc" | undefined
                                ]
                              }
                            </>
                          )}
                      </Flex>
                    )}
                  </MtTable.Th>
                ))}
              </MtTable.Tr>
            ))}
          </MtTable.Thead>
          <MtTable.Tbody>
            {isLoading && (
              <MtTable.Tr>
                <MtTable.Td colSpan={columns.length}>
                  <Skeleton />
                </MtTable.Td>
              </MtTable.Tr>
            )}
            {!isLoading &&
              table.getRowModel().rows.map((row) => (
                <MtTable.Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <MtTable.Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </MtTable.Td>
                  ))}
                </MtTable.Tr>
              ))}
          </MtTable.Tbody>
          {tfoot && <MtTable.Tfoot>{tfoot}</MtTable.Tfoot>}
        </MtTable>
      </ScrollArea>
      {
        !isLoading && table.getPageCount() > 0 && (
          <Flex justify="space-between">
            <Text fw={700}>
              Page {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount().toLocaleString()}
            </Text>
            <Flex gap="xs" mt={0}>
              <Button
                size="xs"
                onClick={() => {
                  table.setPageIndex(0);
                }}
                disabled={!table.getCanPreviousPage()}
                variant="default"
              >
                First
              </Button>
              <Button
                size="xs"
                onClick={() => {
                  table.previousPage();
                }}
                disabled={!table.getCanPreviousPage()}
                variant="default"
              >
                Prev
              </Button>
              <Button
                size="xs"
                onClick={() => {
                  table.nextPage();
                }}
                disabled={!table.getCanNextPage()}
                variant="default"
              >
                Next
              </Button>
              <Button
                size="xs"
                onClick={() => {
                  table.setPageIndex(table.getPageCount() - 1);
                }}
                disabled={!table.getCanNextPage()}
                variant="default"
              >
                Last
              </Button>
            </Flex>
          </Flex>
        )
      }
    </Flex >
  );
};

export default Table;
