import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useForm } from "@mantine/form";
import {
  Alert,
  Button,
  Checkbox,
  FileInput,
  Grid,
  GridCol,
  Loader,
  MantineTheme,
  Table,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { FaFileAlt } from "react-icons/fa";
import ModalButtons from "components/ui/Modal/ModalButtons";

import Acknowledgement from "./Acknowledgement";
import { useFirmwareDntSubmit, useFirmwareDntUpdate } from "hooks/useFirmware";

import defaults from "config/forms.json";
import { MSG } from "constants/msg";
import { DntInfo, FormFileFieldLabels, FormFileProps, FormFileValues, } from "types/module/Firmware";

const DNT_INFO: DntInfo = {
  dntFirmwareFile: "",
  sha256: "",
  product: "",
  firmwareVersion: "",
  modelName: "",
  modelId: "",
  productVersion: "",
  messageDetails: "",
  messageComparison: {},
};

const FIELD_LABELS: FormFileFieldLabels = {
  manufacturer: "Manufacturer",
  releaseNotesUrl: "Release Notes URL",
  comments: "Comments",
  firmwareFile: "DNT Firmware File",
  danteProductFamily: "Dante Product Family",
  danteFirmware: "Dante Firmware Version",
  modelName: "Product Model Name",
  modelVersion: "Product Version",
  modelId: "Model ID",
  agree: "I agree",
};

const FormFile: React.FC<FormFileProps> = ({ firmwareDataRefetch, onClose }) => {
  const form = useForm<FormFileValues>({
    initialValues: {
      releaseNotesUrl: "",
      comments: "",
      firmwareFile: null,
      agree: false,
    },
    validate: {
      releaseNotesUrl: (value: string) =>
        value.length > defaults.input.maxLength
          ? `${FIELD_LABELS.releaseNotesUrl} cannot exceed ${defaults.input.maxLength} characters`
          : null,

      comments: (value: string) =>
        value.length > defaults.textArea.maxLength
          ? `${FIELD_LABELS.comments} cannot exceed ${defaults.textArea.maxLength} characters`
          : null,

      firmwareFile: (value: File | null) => {
        if (!value) return MSG.VALIDATE.REQUIRED(FIELD_LABELS.firmwareFile);

        const fileExtension = value.name?.split(".").pop();
        const isFileTypeValid = fileExtension === "dnt";

        const maxSize = defaults.file.maxSize.dnt * 1024 * 1024;
        const isFileSizeValid = value.size <= maxSize;

        if (!isFileTypeValid)
          return `${FIELD_LABELS.firmwareFile} type must be .dnt`;
        if (!isFileSizeValid)
          return `${FIELD_LABELS.firmwareFile} size cannot exceed ${defaults.file.maxSize.dnt}MB`;

        return null;
      },

      agree: (value: boolean) => {
        if (formsReadOnly && !value) {
          return MSG.VALIDATE.REQUIRED(FIELD_LABELS.agree);
        }
        return null;
      },
    },
  });

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [fileError, setFileError] = useState<string>("");
  const [formsReadOnly, setFormsReadOnly] = useState<boolean>(false);
  const [dntInfo, setDntInfo] = useState<DntInfo>(DNT_INFO);
  const [uploadProgress, setUploadProgress] = useState<number | string>(0);

  const {
    mutate: firmwareSubmitMutate,
    data: firmwareSubmitData,
    isSuccess: isFirmwareSubmitSuccess,
    isError: isFirmwareSubmitError,
  } = useFirmwareDntSubmit(setUploadProgress);

  const {
    mutate: firmwareUpdateMutate,
    isSuccess: isFirmwareUpdateSuccess,
    isError: isFirmwareUpdateError,
  } = useFirmwareDntUpdate();

  useEffect(() => {
    if (isFirmwareSubmitSuccess) {
      if (firmwareSubmitData.data.result === "failed") {
        setFileError(firmwareSubmitData.data.errorMessage);
        setFormsReadOnly(false);
        setUploadProgress(0);
      } else {
        setFileError("");
        setFormsReadOnly(true);

        if (firmwareSubmitData?.data.dntInfo?.dntCapabilities.length > 0) {
          const previousDnt = firmwareSubmitData.data.previousDntCapabilities;

          let messageComparison = null;

          if (previousDnt && previousDnt.length !== 0) {
            messageComparison =
              previousDnt.pending.existingDnt &&
                previousDnt.pending.existingDnt?.length > 0
                ? previousDnt.pending.existingDnt[0].dntCapabilities[0]
                : previousDnt.published.existingDnt &&
                  previousDnt.published.existingDnt.length > 0
                  ? previousDnt.published.existingDnt[0].dntCapabilities[0]
                  : null;
          }

          const dntInfoTemp =
            firmwareSubmitData?.data.dntInfo?.dntCapabilities[0];
          setDntInfo({
            dntFirmwareFile: firmwareSubmitData?.data.fileName,
            sha256: firmwareSubmitData?.data.dntInfo?.sha256,
            id: firmwareSubmitData?.data?.id,
            product: dntInfoTemp?.danteProduct,
            firmwareVersion: dntInfoTemp?.danteFirmwareVersion,
            modelName: dntInfoTemp?.oemModelName,
            modelId: dntInfoTemp?.modelId,
            productVersion: dntInfoTemp?.oemModelVersion,
            messageDetails: firmwareSubmitData?.data?.messageDetails,
            messageComparison,
          });
        }
      }
    }

    if (isFirmwareSubmitSuccess || isFirmwareSubmitError) {
      setIsSubmitting(false);
    }
  }, [isFirmwareSubmitSuccess, isFirmwareSubmitError]);

  useEffect(() => {
    if (isFirmwareUpdateSuccess) {
      handleResetForm();
      onClose();
      firmwareDataRefetch();
    }

    if (isFirmwareUpdateSuccess || isFirmwareUpdateError) {
      setIsSubmitting(false);
    }
  }, [isFirmwareUpdateSuccess, isFirmwareUpdateError]);

  const handleResetForm = () => {
    setUploadProgress(0);
    setFormsReadOnly(false);
    setFileError("");
    setDntInfo(DNT_INFO);

    form.reset();
  };

  const handleFileSubmit = (data: FormFileValues) => {
    setIsSubmitting(true);
    if (formsReadOnly) {
      // @ts-ignore todo: for hooks ts migration
      firmwareUpdateMutate({
        sha256: dntInfo.sha256,
        status: "submit",
        releaseNotesUrl: data.releaseNotesUrl,
        comments: data.comments,
        fileName: dntInfo.dntFirmwareFile,
        fileId: dntInfo.id,
        modelId: dntInfo.modelId,
        modelName: dntInfo.modelName,
        modelVersion: dntInfo.productVersion,
        product: dntInfo.product,
      });
    } else {
      // @ts-ignore todo: for hooks ts migration
      firmwareSubmitMutate({
        file: data.firmwareFile,
      });
    }
  };

  return (
    <form onSubmit={form.onSubmit((values: FormFileValues) => handleFileSubmit(values))}>
      <Grid>
        {!formsReadOnly && (
          <GridCol span={12}>
            Please submit a separate form for DNT files corresponding to each
            product family that you need to upload.
          </GridCol>
        )}

        {formsReadOnly &&
          dntInfo.messageDetails &&
          dntInfo.messageDetails !== "" &&
          dntInfo.messageComparison && (
            <GridCol span={12}>
              <Alert color="yellow" title="Note">
                <Text size="xs">{dntInfo.messageDetails}</Text>
                <Table
                  style={(theme: MantineTheme) => ({
                    fontSize: theme.fontSizes.xs,
                    lineHeight: "1",
                  })}
                >
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th></Table.Th>
                      <Table.Th>Existing file</Table.Th>
                      <Table.Th>Your uploaded file</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    <Table.Tr>
                      <Table.Th>{FIELD_LABELS.manufacturer}</Table.Th>
                      <Table.Td>
                        {dntInfo.messageComparison.manufacturerName || "-"}
                      </Table.Td>
                      <Table.Td>
                        {dntInfo.messageComparison.manufacturerName || "-"}
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Th>{FIELD_LABELS.danteProductFamily}</Table.Th>
                      <Table.Td>
                        {dntInfo.messageComparison.danteProduct || "-"}
                      </Table.Td>
                      <Table.Td>{dntInfo.product}</Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Th>{FIELD_LABELS.danteFirmware}</Table.Th>
                      <Table.Td
                        c={`${dntInfo.messageComparison.danteFirmwareVersion !==
                          dntInfo.firmwareVersion && "red"
                          }`}
                      >
                        {dntInfo.messageComparison.danteFirmwareVersion || "-"}
                      </Table.Td>
                      <Table.Td
                        c={`${dntInfo.messageComparison.danteFirmwareVersion !==
                          dntInfo.firmwareVersion && "red"
                          }`}
                      >
                        {dntInfo.firmwareVersion || "-"}
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Th>{FIELD_LABELS.modelName}</Table.Th>
                      <Table.Td
                        c={`${dntInfo.messageComparison.oemModelName !==
                          dntInfo.modelName && "red"
                          }`}
                      >
                        {dntInfo.messageComparison.oemModelName || "-"}
                      </Table.Td>
                      <Table.Td
                        c={`${dntInfo.messageComparison.oemModelName !==
                          dntInfo.modelName && "red"
                          }`}
                      >
                        {dntInfo.modelName || "-"}
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Th>{FIELD_LABELS.modelId}</Table.Th>
                      <Table.Td
                        c={`${dntInfo.messageComparison.modelId !==
                          dntInfo.modelId && "red"
                          }`}
                      >
                        {dntInfo.messageComparison.modelId || "-"}
                      </Table.Td>
                      <Table.Td
                        c={`${dntInfo.messageComparison.modelId !==
                          dntInfo.modelId && "red"
                          }`}
                      >
                        {dntInfo.modelId || "-"}
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Th>{FIELD_LABELS.modelVersion}</Table.Th>
                      <Table.Td
                        c={`${dntInfo.messageComparison.oemModelVersion !==
                          dntInfo.productVersion && "red"
                          }`}
                      >
                        {dntInfo.messageComparison.oemModelVersion || "-"}
                      </Table.Td>
                      <Table.Td
                        c={`${dntInfo.messageComparison.oemModelVersion !==
                          dntInfo.productVersion && "red"
                          }`}
                      >
                        {dntInfo.productVersion || "-"}
                      </Table.Td>
                    </Table.Tr>
                  </Table.Tbody>
                </Table>
              </Alert>
            </GridCol>
          )}

        <GridCol span={12}>
          <TextInput
            label={FIELD_LABELS.manufacturer}
            value={Cookies.get("manufacturer")}
            readOnly
          />
        </GridCol>
        <GridCol span={{ base: 12, md: 6 }}>
          <TextInput
            key={form.key("releaseNotesUrl")}
            label={FIELD_LABELS.releaseNotesUrl}
            rightSection="optional"
            placeholder={`Enter ${FIELD_LABELS.releaseNotesUrl}`}
            disabled={formsReadOnly || isSubmitting}
            {...form.getInputProps("releaseNotesUrl")}
          />
        </GridCol>
        <GridCol span={{ base: 12, md: 6 }}>
          <Textarea
            key={form.key("comments")}
            label={FIELD_LABELS.comments}
            rightSection="optional"
            placeholder={`Enter ${FIELD_LABELS.comments}`}
            disabled={formsReadOnly || isSubmitting}
            rows={1}
            {...form.getInputProps("comments")}
          />
        </GridCol>
        {!formsReadOnly && (
          <GridCol span={12}>
            <FileInput
              key={form.key("firmwareFile")}
              label={FIELD_LABELS.firmwareFile}
              leftSection={<FaFileAlt />}
              placeholder={`Attach ${FIELD_LABELS.firmwareFile}`}
              leftSectionPointerEvents="none"
              accept=".dnt"
              disabled={isSubmitting}
              {...form.getInputProps("firmwareFile")}
            />
            <Text fz="xs" color="red">
              {fileError}
            </Text>

            <Text fz="xs">
              Note: Please do not upload test firmware, upload production
              firmware only. Uploaded files will be visible to all users of the
              Dante Updater application.
            </Text>
          </GridCol>
        )}
        {formsReadOnly && (
          <>
            <GridCol span={{ base: 12, md: 6 }}>
              <TextInput
                label={FIELD_LABELS.firmwareFile}
                value={dntInfo.dntFirmwareFile}
                readOnly
              />
            </GridCol>
            <GridCol span={{ base: 12, md: 6 }}>
              <TextInput
                label={FIELD_LABELS.danteProductFamily}
                value={dntInfo.product}
                readOnly
              />
            </GridCol>
            <GridCol span={{ base: 12, md: 6 }}>
              <TextInput
                label={FIELD_LABELS.danteFirmware}
                value={dntInfo.firmwareVersion}
                readOnly
              />
            </GridCol>
            <GridCol span={{ base: 12, md: 6 }}>
              <TextInput
                label={FIELD_LABELS.modelName}
                value={dntInfo.modelName}
                readOnly
              />
            </GridCol>
            <GridCol span={{ base: 12, md: 6 }}>
              <TextInput
                label={FIELD_LABELS.modelVersion}
                value={dntInfo.productVersion}
                readOnly
              />
            </GridCol>
            <GridCol span={12}>
              <Acknowledgement>
                <Checkbox
                  key={form.key("agree")}
                  label="I agree"
                  value={1}
                  {...form.getInputProps("agree")}
                  mt="md"
                />
              </Acknowledgement>
            </GridCol>
          </>
        )}
      </Grid>
      <ModalButtons
        start={
          <Button
            onClick={handleResetForm}
            disabled={isSubmitting}
            color="orange"
          >
            {!formsReadOnly ? "Reset Form" : "Cancel and Upload Another"}
          </Button>
        }
      >
        {!formsReadOnly && (
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting && <Loader size={15} color="gray" mr="sm" />}
            {isSubmitting &&
              (uploadProgress !== 100
                ? `Uploading (${uploadProgress}%)`
                : `Submitting...`)}
            {!isSubmitting && "Submit Firmware File"}
          </Button>
        )}

        {formsReadOnly && (
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting && <Loader size={15} color="gray" mr="sm" />}
            Confirm and Finalize Submission
          </Button>
        )}
        <Button variant="default" onClick={onClose}>
          Close
        </Button>
      </ModalButtons>
    </form>
  );
};

export default FormFile;