import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

import MainLayout from "./components/layouts/MainLayout";
import { PrivateRoutes } from "./utils/PrivateRoutes";
import { PermissionRoute } from "./utils/PermissionRoute";

import Home from "./pages/Home";
import Firmware from "./pages/Firmware/Firmware";
import License from "./pages/License/License";
import User from "./pages/User/User";
import ComingSoon from "./pages/ComingSoon";
import Page404 from "./pages/Page404";
import Restricted from "./pages/Restricted";
import Logout from "./pages/Logout";
import AutoLogout from "./pages/AutoLogout";
import Accept from "./pages/Accept";

function App() {
  return (
    <BrowserRouter
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath: true,
      }}
    >
      <Auth0Provider
        domain={window.env.REACT_APP_AUTH0_DOMAIN}
        clientId={window.env.REACT_APP_AUTH0_CLIENTID}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: `${window.env.REACT_APP_AUTH0_URL}api/v2/`,
          scope: "openid read:current_user",
        }}
      >
        <Routes>
          <Route exact path="/auto-logout" element={<AutoLogout />} />
          <Route exact path="/accept" element={<Accept />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<MainLayout />}>
              <Route exact path="" element={<Home />} />
              <Route
                exact
                path="firmware"
                element={
                  <PermissionRoute requiredPermission="firmwareUpload">
                    <Firmware />
                  </PermissionRoute>
                }
              />
              <Route
                exact
                path="license"
                element={
                  <PermissionRoute requiredPermission="licenseView">
                    <License />
                  </PermissionRoute>
                }
              />
              <Route
                exact
                path="user"
                element={
                  <PermissionRoute requiredPermission="userManagement">
                    <User />
                  </PermissionRoute>
                }
              />
              <Route exact path="coming-soon" element={<ComingSoon />} />
              <Route exact path="404" element={<Page404 />} />
              <Route exact path="restricted" element={<Restricted />} />
              <Route exact path="logout" element={<Logout />} />
              <Route path="*" element={<Restricted />} />
            </Route>
          </Route>
        </Routes>
      </Auth0Provider>
    </BrowserRouter>
  );
}

export default App;
