import { Flex, Group } from "@mantine/core";
import { ModalButtonsProps } from "types/ui";

const ModalButtons: React.FC<ModalButtonsProps> = ({
  children,
  start = null,
}) => {
  return (
    <Flex justify="space-between" mt="lg">
      <Group>{start}</Group>
      <Group>{children}</Group>
    </Flex>
  );
};
export default ModalButtons;
