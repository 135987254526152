import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Divider, Flex, Image, Title } from "@mantine/core";

const AutoLogout: React.FC = () => {
  const [seconds, setSeconds] = useState<number>(10);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prev) => prev - 1);
    }, 1000);

    if (seconds <= 0) {
      clearInterval(interval);
      navigate("/");
    }

    return () => clearInterval(interval);
  }, [seconds, navigate]);

  return (
    <Flex
      h="100vh"
      justify="center"
      align="center"
      direction="column"
      ta="center"
    >
      <div>
        <Image src="/oops.gif" m="auto" w="200px" />
        <Title order={3}>
          Your company administrator has changed
          <br />
          permissions associated with your profile.
        </Title>
        Please login again to continue accessing the portal.
        <Divider my="lg" />
        You will be automatically redirected to login page in {seconds} seconds
      </div>
    </Flex>
  );
};

export default AutoLogout;
