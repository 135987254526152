import { Box, Flex, Pill } from "@mantine/core";

const BannerTop: React.FC = () => {
  return (
    <Flex direction="column">
      The Dante Firmware Manager is a tool for uploading new manufacturer device
      firmware (DNT files) for access by users in the field via the Dante
      Updater software (accessible in Dante Controller).
      <Box>
        Either:{" "}
        <Pill mx="xs">
          1. Choose Upload Firmware File to submit your DNT file
        </Pill>
        or
        <Pill mx="xs">
          2. Notify end-users that new firmware is available via a URL link.
        </Pill>
      </Box>
    </Flex>
  );
};

export default BannerTop;
