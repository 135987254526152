import { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";

import { useUsers, useUserOffboard, useUserUpdate } from "../../hooks/useUser";

import {
  Button,
  Loader,
  Switch,
  Modal,
  NativeSelect,
  SegmentedControl,
  Divider,
} from "@mantine/core";
import { ColumnDef } from "@tanstack/react-table";
import HeaderTitle from "../../components/ui/Header/HeaderTitle";
import Table from "../../components/ui/Table/Table";
import { ROLES } from "constants/roles";

import BannerBottom from "./BannerBottom";
import ModalButtons from "../../components/ui/Modal/ModalButtons";

import { UserProps } from "types/module/User";
import { RoleProps } from "types/constants";

const User: React.FC = () => {
  document.title = "Manage Users";

  const {
    data: userData,
    isLoading: isUserDataLoading,
    isSuccess: isUserDataSuccess,
    refetch: userDataRefetch,
  } = useUsers();

  const {
    mutate: userOffboardMutate,
    isSuccess: userOffboardSuccess,
    isError: userOffboardError,
  } = useUserOffboard();

  const {
    mutate: userUpdateMutate,
    isSuccess: userUpdateSuccess,
    isError: userUpdateError,
  } = useUserUpdate();

  const [users, setUsers] = useState<UserProps[]>([]);
  const [isOnlyAdmin, setIsOnlyAdmin] = useState<boolean>(false);

  const [filters, setFilters] = useState<Record<string, string>>({
    status: "",
  });

  const [isOpenOffboardPrompt, setIsOpenOffboardPrompt] = useState<boolean>(false);
  const [selectedUserOffboard, setSelectedUserOffboard] = useState<UserProps | null>(null);

  const [isOpenProfileChangePrompt, setIsOpenProfileChangePrompt] =
    useState<boolean>(false);
  const [selectedUserProfileChange, setSelectedUserProfileChange] =
    useState<UserProps | null>(null);
  const [selectedProfile, setSelectedProfile] = useState<string | null>(null);

  const [isOpenStatusChangePrompt, setIsOpenStatusChangePrompt] =
    useState<boolean>(false);
  const [selectedUserStatusChange, setSelectedUserStatusChange] =
    useState<UserProps | null>(null);

  const [isOpenInvalidPrompt, setIsOpenInvalidPrompt] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const columns: ColumnDef<UserProps>[] = [
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Profile",
      cell: ({ row }) => (
        <NativeSelect
          size="xs"
          w={200}
          data={ROLES.map((item: RoleProps) => item.value)}
          value={row.original.profile}
          onChange={(event) =>
            handleOpenProfileChangePrompt(
              row.original,
              event.currentTarget.value
            )
          }
          disabled={isSubmitting}
        />
      ),
      size: 300,
      enableSorting: false,
    },
    {
      header: "Active",
      cell: ({ row }) => (
        <Switch
          size="md"
          checked={
            selectedUserStatusChange?.Id == row.original.Id
              ? !row.original.isActive
              : row.original.isActive
          }
          disabled={isSubmitting}
          thumbIcon={
            selectedUserStatusChange?.Id == row.original.Id ? (
              <Loader color="gray" size={13} />
            ) : null
          }
          onChange={() => handleOpenStatusChangePrompt(row.original)}
        />
      ),
      size: 100,
      enableSorting: false,
    },
    {
      header: "Actions",
      cell: ({ row }) => (
        <Button
          color="red"
          size="xs"
          disabled={isSubmitting}
          loading={selectedUserOffboard?.Id == row.original.Id}
          onClick={() => handleOpenOffboardPrompt(row.original)}
        >
          <FaTrash />
        </Button>
      ),
      size: 100,
      enableSorting: false,
    },
  ];

  const handleOpenOffboardPrompt = (user: UserProps) => {
    if (isOnlyAdmin && user.profile == "Admin" && user.isActive) {
      setIsOpenInvalidPrompt(true);
    } else {
      setSelectedUserOffboard(user);
      setIsOpenOffboardPrompt(true);
    }
  };

  const handleOffboard = (user: UserProps) => {
    setIsSubmitting(true);
    setIsOpenOffboardPrompt(false);

    // @ts-ignore todo: for hooks ts migration
    userOffboardMutate({
      userId: user.Id,
      name: user.name,
    });
  };

  const handleOpenProfileChangePrompt = (user: UserProps, profile: string) => {
    if (isOnlyAdmin && user.profile == "Admin" && user.isActive) {
      setIsOpenInvalidPrompt(true);
    } else {
      setSelectedUserProfileChange(user);

      if (profile !== "Admin") {
        if (userData?.currentUser?.Id == user.Id) {
          setIsOpenProfileChangePrompt(true);
          setSelectedProfile(profile);
        } else {
          handleProfileChange(user, profile);
        }
      } else {
        setIsOpenProfileChangePrompt(true);
      }
    }
  };

  const handleProfileChange = (user: UserProps, profile = "Admin") => {
    setIsSubmitting(true);
    setIsOpenProfileChangePrompt(false);
    setSelectedProfile(null);

    // @ts-ignore todo: for hooks ts migration
    userUpdateMutate({
      userId: user.Id,
      name: user.name,
      profile: profile,
    });
  };

  const handleOpenStatusChangePrompt = (user: UserProps) => {
    if (isOnlyAdmin && user.profile == "Admin" && user.isActive) {
      setIsOpenInvalidPrompt(true);
    } else {
      setSelectedUserStatusChange(user);

      if (userData?.currentUser?.Id == user.Id) {
        setIsOpenStatusChangePrompt(true);
      } else {
        handleStatusChange(user);
      }
    }
  };

  const handleStatusChange = (user: UserProps) => {
    setIsSubmitting(true);
    setIsOpenStatusChangePrompt(false);

    // @ts-ignore todo: for hooks ts migration
    userUpdateMutate({
      userId: user.Id,
      name: user.name,
      isActive: !user.isActive,
    });
  };

  const handleClosePrompt = () => {
    setSelectedUserOffboard(null);
    setIsOpenOffboardPrompt(false);

    setSelectedUserProfileChange(null);
    setIsOpenProfileChangePrompt(false);
    setSelectedProfile(null);

    setSelectedUserStatusChange(null);
    setIsOpenStatusChangePrompt(false);

    setIsOpenInvalidPrompt(false);
  };

  useEffect(() => {
    if (isUserDataSuccess) {
      setUsers(userData?.userList);

      setIsOnlyAdmin(
        userData?.userList?.filter(
          (item: UserProps) => item.profile == "Admin" && item.isActive
        ).length <= 1
      );
    }
  }, [isUserDataSuccess, userData]);

  useEffect(() => {
    if (
      userOffboardSuccess ||
      userOffboardError ||
      userUpdateSuccess ||
      userUpdateError
    ) {
      userDataRefetch().then(() => {
        setIsSubmitting(false);
        setSelectedUserStatusChange(null);
        setSelectedUserProfileChange(null);
        handleClosePrompt();
      });
    }
  }, [
    userOffboardSuccess,
    userOffboardError,
    userUpdateSuccess,
    userUpdateError,
  ]);

  return (
    <>
      <HeaderTitle title="Manage Users">
        <SegmentedControl
          data={[
            {
              label: "All",
              value: "",
            },
            {
              label: "Active",
              value: "active",
            },
            {
              label: "Inactive",
              value: "inactive",
            },
          ]}
          onChange={(value) =>
            setFilters((prev) => ({
              ...prev,
              status: value,
            }))
          }
        />
      </HeaderTitle>
      <Table
        data={users?.filter((item) => {
          return filters.status !== ""
            ? (item.isActive ? "active" : "inactive") === filters.status
            : true;
        })}
        columns={columns}
        isLoading={isUserDataLoading}
      />
      <Modal
        title="Are you sure?"
        opened={isOpenOffboardPrompt}
        onClose={handleClosePrompt}
      >
        This will remove <b>{selectedUserOffboard?.name}&apos;s</b> access to
        the DNA portal entirely. To re-enable access for{" "}
        <b>{selectedUserOffboard?.name}</b> you will have to go through the
        registration process again. Are you happy to proceed?
        <ModalButtons>
          <Button variant="default" onClick={handleClosePrompt}>
            Cancel
          </Button>
          <Button
            color="red"
            onClick={() => handleOffboard(selectedUserOffboard)}
          >
            Remove
          </Button>
        </ModalButtons>
      </Modal>
      <Modal
        title="Are you sure?"
        opened={isOpenProfileChangePrompt}
        onClose={handleClosePrompt}
      >
        {userData?.currentUser?.Id == selectedUserProfileChange?.Id && (
          <>
            Are you sure you want to change your own account to{" "}
            <b>{selectedProfile}</b> Profile?
          </>
        )}
        {userData?.currentUser?.Id != selectedUserProfileChange?.Id && (
          <>
            Changing <b>{selectedUserProfileChange?.name}&apos;s</b> profile to
            Admin will allow them to administer all other users of the portal
            and provide access to firmware and licensing tools. Are you happy to
            proceed?
          </>
        )}
        <ModalButtons>
          <Button variant="default" onClick={handleClosePrompt}>
            Cancel
          </Button>
          <Button
            onClick={() => handleProfileChange(selectedUserProfileChange)}
          >
            Change
          </Button>
        </ModalButtons>
      </Modal>
      <Modal
        title="Are you sure?"
        opened={isOpenStatusChangePrompt}
        onClose={handleClosePrompt}
      >
        Are you sure you want to de-activate your own account?
        <ModalButtons>
          <Button variant="default" onClick={handleClosePrompt}>
            Cancel
          </Button>
          <Button
            color="red"
            onClick={() => handleStatusChange(selectedUserStatusChange)}
          >
            Deactivate
          </Button>
        </ModalButtons>
      </Modal>
      <Modal
        title="Invalid action"
        opened={isOpenInvalidPrompt}
        onClose={handleClosePrompt}
      >
        There needs to be at least one admin user profile in your account at all
        times.
        <ModalButtons>
          <Button variant="default" onClick={handleClosePrompt}>
            I understand
          </Button>
        </ModalButtons>
      </Modal>
      <Divider my="md" />
      <BannerBottom />
    </>
  );
};

export default User;
