import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useUserInviteAccept } from "../hooks/useUser";
import {
  Anchor,
  Flex,
  Image,
  Loader,
  Text,
  Title,
  Transition,
} from "@mantine/core";
import { TransitionDefault } from "../mantineTheme";

const Accept = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const invite = queryParams.get("invite");

  const { data, isSuccess, isError, refetch } = useUserInviteAccept(invite);

  useEffect(() => {
    if (invite) {
      refetch({
        invitationId: invite,
      });
    }
  }, [invite]);

  return (
    <Flex
      h="100vh"
      justify="center"
      align="center"
      direction="column"
      ta="center"
    >
      <Transition {...TransitionDefault} mounted={!isSuccess && !isError}>
        {(style) => (
          <Flex style={style} direction="column">
            <Loader color="blue" m="auto" size="xl" />
            <Title order={2} mt="lg">
              Validating invite
            </Title>
          </Flex>
        )}
      </Transition>
      <Transition
        {...TransitionDefault}
        mounted={isSuccess && data?.status == "success"}
      >
        {(style) => (
          <Flex style={style} direction="column">
            <Image src="/congrats.gif" m="auto" w="200px" />
            <Title order={2} mt="lg">
              Thank you for accepting the invite!
            </Title>
            <Text size="lg">
              Please proceed with creating an account at {" "}
              <Anchor href="https://my.audinate.com/user/register" underline="hover">
                my.audinate.com/user/register
              </Anchor>.
              <br />Once your account is set up, we will process it on our backend, and you will receive an email soon
              <br />with the details for your access. If you have any questions, feel free to reach out.
            </Text>
          </Flex>
        )}
      </Transition>
      <Transition
        {...TransitionDefault}
        mounted={isError || data?.status == "failed"}
      >
        {(style) => (
          <Flex style={style} direction="column">
            <Image src="/oops.gif" m="auto" w="200px" />
            <Title order={2}>Invite processing failed!</Title>
            <Text size="lg">
              Either the invite has expired or already accepted.
            </Text>
          </Flex>
        )}
      </Transition>
    </Flex>
  );
};

export default Accept;
