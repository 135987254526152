import { Title } from "@mantine/core";

const Restricted: React.FC = () => {
  return (
    <Title order={3} ta="center">
      Looks like you do not have sufficient permission to view this page,
      <br />
      please contact support if required.
    </Title>
  );
};

export default Restricted;
