import { Flex, Title } from "@mantine/core";
import { HeaderTitleProps } from "types/ui";

const HeaderTitle: React.FC<HeaderTitleProps> = ({ title = "", children }) => {
  return (
    <Flex justify="space-between" align="center" mb="xl">
      <Title>{title}</Title>
      <Flex mt="0" align="center" gap="sm">
        {children}
      </Flex>
    </Flex>
  );
};



export default HeaderTitle;
