import { List, Paper, Text } from "@mantine/core";
import { AcknowledgementProps } from "types/module/Firmware";

const Acknowledgement: React.FC<AcknowledgementProps> = ({ children }) => {
  return (
    <Paper withBorder p="md">
      <b>Terms and Conditions </b>
      <Text mt="xs" size="xs">
        By marking the checkbox below, you acknowledge that:
      </Text>
      <List
        listStyleType="disc"
        size="xs"
        styles={{
          item: { marginRight: "10px" },
        }}
      >
        <List.Item>
          You are an employee of the manufacturer listed above (“Manufacturer“).
        </List.Item>
        <List.Item>
          You have the authority to agree to these Terms and Conditions on
          behalf of Manufacturer, or if not, that you have obtained the written
          permission of an authorised representative.
        </List.Item>
        <List.Item>
          The firmware has been validated and the Manufacturer takes
          responsibility for any issues resulting from this firmware being used
          to update Manufacturer&apos;s products in the field. Specifically,
          Manufacturer does not hold Audinate Pty Ltd or its associates
          (“Audinate“) liable for any loss, damage, expense or injury that may
          arise as a result of its use of this service.
        </List.Item>
        <List.Item>
          Manufacturer authorises Audinate to distribute the firmware and update
          Dante devices.
        </List.Item>
      </List>
      {children}
    </Paper>
  );
};

export default Acknowledgement;
