import { Title } from "@mantine/core";

const Page404: React.FC = () => {
  return (
    <Title order={3} ta="center">
      404: Not found.
    </Title>
  );
};

export default Page404;
