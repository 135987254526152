import { useState } from "react";
import { Button, Divider, Modal, ScrollArea } from "@mantine/core";

import FormNotification from "./FormNotification";
import FormFile from "./FormFile";
import TableSubmissions from "./TableSubmissions";

import { useFirmwares } from "../../hooks/useFirmware";
import HeaderTitle from "components/ui/Header/HeaderTitle";

import BannerTop from "./BannerTop";
import BannerBottom from "./BannerBottom";
const Firmware: React.FC = () => {
  document.title = "Dante Firmware Manager";

  const [isNotificationModal, setIsNotificationModal] = useState<boolean>(false);
  const [isFileModal, setIsFileModal] = useState<boolean>(false);

  const {
    data: firmwareData,
    isLoading: isFirmwareDataLoading,
    refetch: firmwareDataRefetch,
  } = useFirmwares();

  return (
    <>
      <HeaderTitle title="Dante Firmware Manager">
        <Button onClick={() => setIsNotificationModal(true)}>
          Upload Firmware URL
        </Button>
        <Button onClick={() => setIsFileModal(true)}>Upload Firmware File</Button>
      </HeaderTitle>
      <BannerTop />
      <TableSubmissions
        data={firmwareData}
        isLoading={isFirmwareDataLoading}
      />
      <Divider my="md" />
      <BannerBottom />
      <Modal
        size="lg"
        scrollAreaComponent={ScrollArea.Autosize}
        title="Upload Firmware URL"
        opened={isNotificationModal}
        onClose={() => setIsNotificationModal(false)}
      >
        <FormNotification
          firmwareDataRefetch={firmwareDataRefetch}
          onClose={() => setIsNotificationModal(false)}
        />
      </Modal>
      <Modal
        size="lg"
        scrollAreaComponent={ScrollArea.Autosize}
        title="Upload Firmware File"
        opened={isFileModal}
        onClose={() => setIsFileModal(false)}
      >
        <FormFile
          firmwareDataRefetch={firmwareDataRefetch}
          onClose={() => setIsFileModal(false)}
        />
      </Modal>
    </>
  );
};

export default Firmware;
