import { useEffect, useState } from "react";
import _ from "lodash";

import { isVersion } from "utils/FormValidation";
import Cookies from "js-cookie";

import { isNotEmpty, useForm } from "@mantine/form";
import {
  Button,
  Checkbox,
  Select,
  Textarea,
  TextInput,
  Tooltip,
  Loader,
  Autocomplete,
  Grid,
  GridCol,
  Flex,
} from "@mantine/core";
import ModalButtons from "components/ui/Modal/ModalButtons";
import { FaQuestionCircle } from "react-icons/fa";

import defaults from "config/forms.json";
import { MSG } from "constants/msg";
import { FormNotifFields, FormNotifProps } from "types/module/Firmware";

import { useDeviceCodes } from "hooks/useDeviceCode";
import { useFirewareVersions } from "hooks/useFirewareVersion";
import { useOemModelIds } from "hooks/useOemModelId";
import { useFirmwareNotificationSubmit } from "hooks/useFirmware";

import Acknowledgement from "./Acknowledgement";

const PRODUCT_TYPE = ["Numeric", "String"];

const FIELD_LABELS: FormNotifFields = {
  manufacturer: "Manufacturer",
  deviceCode: "Dante Product Family",
  danteFirmware: "Dante Firmware Version",
  modelName: "Product Model Name",
  modelId: "Model ID",
  modelVersionType: "Product Version Number Type",
  modelVersion: "Product Version",
  softwareDownloadUrl: "Software Download URL",
  releaseNotesUrl: "Release Notes URL",
  comments: "Comments",
  agree: "I agree",
};

const FormNotification: React.FC<FormNotifProps> = ({ firmwareDataRefetch, onClose }) => {
  const form = useForm<FormNotifFields>({
    initialValues: {
      deviceCode: "",
      danteFirmware: "",
      modelName: "",
      modelId: "",
      modelVersionType: "",
      modelVersion: "",
      softwareDownloadUrl: "",
      releaseNotesUrl: "",
      comments: "",
      agree: false,
    },
    validate: {
      deviceCode: isNotEmpty(MSG.VALIDATE.REQUIRED(FIELD_LABELS.deviceCode)),
      danteFirmware: isNotEmpty(
        MSG.VALIDATE.REQUIRED(FIELD_LABELS.danteFirmware)
      ),
      modelName: isNotEmpty(MSG.VALIDATE.REQUIRED(FIELD_LABELS.modelName)),
      modelId: isNotEmpty(MSG.VALIDATE.REQUIRED(FIELD_LABELS.modelId)),
      modelVersionType: isNotEmpty(
        MSG.VALIDATE.REQUIRED(FIELD_LABELS.modelVersionType)
      ),
      modelVersion: (value: string | null) => {
        const isVersionResult = isVersion(value, form.values.modelVersionType);

        if (!value.trim())
          return MSG.VALIDATE.REQUIRED(FIELD_LABELS.modelVersion);
        if (isVersionResult !== true) return isVersionResult;
        return null;
      },
      softwareDownloadUrl: (value: string | null) => {
        if (!value.trim())
          return MSG.VALIDATE.REQUIRED(FIELD_LABELS.softwareDownloadUrl);
        if (value.length > defaults.input.maxLength)
          return `${FIELD_LABELS.softwareDownloadUrl} cannot exceed ${defaults.input.maxLength} characters`;
        return null;
      },
      releaseNotesUrl: (value: string | null) =>
        value.length > defaults.input.maxLength
          ? `${FIELD_LABELS.releaseNotesUrl} cannot exceed ${defaults.input.maxLength} characters`
          : null,
      comments: (value: string | null) =>
        value.length > defaults.textArea.maxLength
          ? `${FIELD_LABELS.releaseNotesUrl} cannot exceed ${defaults.textArea.maxLength} characters`
          : null,
      agree: isNotEmpty(MSG.VALIDATE.REQUIRED(FIELD_LABELS.agree)),
    },
  });

  form.watch(
    "deviceCode",
    ({ dirty }: any) => dirty && form.setFieldValue("danteFirmware", null)
  );

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { data: deviceCodeData, isFetching: isDeviceCodeDataLoading } =
    useDeviceCodes();
  const {
    data: firewareVersionData,
    isFetching: isFirewareVersionDataLoading,
    refetch: firewareVersionRefetch,
  } = useFirewareVersions(
    _.isEmpty(form?.values?.deviceCode)
      ? null
      : JSON.parse(form.values.deviceCode)[0]
  );
  const {
    data: oemModelIdData,
    isFetching: isOemModelIdDataLoading,
    refetch: oemModelIdDataRefetch,
  } = useOemModelIds(
    _.isEmpty(form?.values?.deviceCode)
      ? null
      : JSON.parse(form.values.deviceCode)[0]
  );
  const {
    mutate: firmwareSubmitMutate,
    isSuccess: isFirmwareSubmitSuccess,
    isError: isFirmwareSubmitError,
  } = useFirmwareNotificationSubmit();

  const handleNotificationSubmit = (data: FormNotifFields) => {
    setIsSubmitting(true);

    if (
      !_.isEmpty(data.deviceCode) &&
      JSON.parse(data.deviceCode).length === 2
    ) {
      // @ts-ignore todo: for hooks ts migration
      firmwareSubmitMutate({
        deviceCode: JSON.parse(data.deviceCode)[0],
        deviceCodeName: JSON.parse(data.deviceCode)[1],
        danteFirmware: data.danteFirmware,
        modelName: data.modelName,
        modelId: data.modelId,
        modelVersion: data.modelVersion,
        modelVersionType: data.modelVersionType,
        softwareDownloadUrl: data.softwareDownloadUrl,
        releaseNotesUrl: data.releaseNotesUrl,
        comments: data.comments,
        publicationDate: Date.now(),
      });
    }
  };

  useEffect(() => {
    if (
      !_.isEmpty(form?.values?.deviceCode) &&
      JSON.parse(form.values.deviceCode).length === 2
    ) {
      oemModelIdDataRefetch();
      firewareVersionRefetch();
    }
  }, [form.values.deviceCode, firewareVersionRefetch, oemModelIdDataRefetch]);

  useEffect(() => {
    if (isFirmwareSubmitSuccess || isFirmwareSubmitError) {
      setIsSubmitting(false);

      if (isFirmwareSubmitSuccess) {
        firmwareDataRefetch();
        onClose();
        form.reset();
      }
    }
  }, [isFirmwareSubmitSuccess, isFirmwareSubmitError]);

  return (
    <>
      <form
        onSubmit={form.onSubmit((values: FormNotifFields) => handleNotificationSubmit(values))}
      >
        <Grid>
          <GridCol span={12}>
            <TextInput
              label={FIELD_LABELS.manufacturer}
              value={Cookies.get("manufacturer")}
              readOnly
            />
          </GridCol>
          <GridCol span={{ base: 12, md: 6 }}>
            <Select
              key={form.key("deviceCode")}
              label={FIELD_LABELS.deviceCode}
              placeholder={`Select ${FIELD_LABELS.deviceCode}`}
              data={
                deviceCodeData &&
                deviceCodeData.map((item: any) => ({
                  label: item.name,
                  value: JSON.stringify([item.deviceCode, item.name]),
                }))
              }
              rightSection={isDeviceCodeDataLoading && <Loader size={15} />}
              {...form.getInputProps("deviceCode")}
            />
          </GridCol>
          <GridCol span={{ base: 12, md: 6 }}>
            <Select
              key={form.key("danteFirmware")}
              label={FIELD_LABELS.danteFirmware}
              placeholder={`Select ${FIELD_LABELS.danteFirmware}`}
              data={
                firewareVersionData &&
                firewareVersionData.map((item: any) => ({
                  label: item,
                  value: item,
                }))
              }
              rightSection={
                isFirewareVersionDataLoading && <Loader size={15} />
              }
              {...form.getInputProps("danteFirmware")}
            />
          </GridCol>
          <GridCol span={{ base: 12, md: 6 }}>
            <TextInput
              key={form.key("modelName")}
              label={
                <Tooltip multiline w={400} label={MSG.HELP.PRODUCT_MODEL_NAME}>
                  <Flex justify="space-between" align="center" gap="xs" mt="0">
                    Product Model Name
                    <FaQuestionCircle />
                  </Flex>
                </Tooltip>
              }
              placeholder={`Enter ${FIELD_LABELS.modelName}`}
              {...form.getInputProps("modelName")}
            />
          </GridCol>
          <GridCol span={{ base: 12, md: 6 }}>
            <Autocomplete
              key={form.key("modelId")}
              label={FIELD_LABELS.modelId}
              placeholder={`Enter ${FIELD_LABELS.modelId}`}
              data={
                oemModelIdData &&
                oemModelIdData.map((item: any) => ({
                  label: item,
                  value: item,
                }))
              }
              rightSection={isOemModelIdDataLoading && <Loader size={15} />}
              {...form.getInputProps("modelId")}
            />
          </GridCol>
          <GridCol span={{ base: 12, md: 6 }}>
            <Select
              key={form.key("modelVersionType")}
              label={FIELD_LABELS.modelVersionType}
              placeholder={`Select ${FIELD_LABELS.modelVersionType}`}
              data={PRODUCT_TYPE.map((item: any) => ({
                label: item,
                value: item,
              }))}
              {...form.getInputProps("modelVersionType")}
            />
          </GridCol>
          <GridCol span={{ base: 12, md: 6 }}>
            <TextInput
              key={form.key("modelVersion")}
              label={FIELD_LABELS.modelVersion}
              placeholder={`Select ${FIELD_LABELS.modelVersion}`}
              {...form.getInputProps("modelVersion")}
            />
          </GridCol>
          <GridCol span={{ base: 12, md: 6 }}>
            <TextInput
              key={form.key("softwareDownloadUrl")}
              label={FIELD_LABELS.softwareDownloadUrl}
              placeholder={`Enter ${FIELD_LABELS.softwareDownloadUrl}`}
              {...form.getInputProps("softwareDownloadUrl")}
            />
          </GridCol>
          <GridCol span={{ base: 12, md: 6 }}>
            <TextInput
              key={form.key("releaseNotesUrl")}
              label={FIELD_LABELS.releaseNotesUrl}
              rightSection="optional"
              placeholder={`Enter ${FIELD_LABELS.releaseNotesUrl}`}
              {...form.getInputProps("releaseNotesUrl")}
            />
          </GridCol>
          <GridCol span={12}>
            <Textarea
              key={form.key("comments")}
              label={FIELD_LABELS.comments}
              rightSection="optional"
              placeholder={`Enter ${FIELD_LABELS.comments}`}
              {...form.getInputProps("comments")}
            />
          </GridCol>
          <GridCol span={12}>
            <Acknowledgement>
              <Checkbox
                key={form.key("agree")}
                label="I agree"
                value={1}
                {...form.getInputProps("agree")}
                mt="md"
              />
            </Acknowledgement>
          </GridCol>
        </Grid>
        <ModalButtons>
          <Button loading={isSubmitting} type="submit">
            Submit Firmware Notification
          </Button>
          <Button variant="default" onClick={onClose}>
            Close
          </Button>
        </ModalButtons>
      </form>
    </>
  );
};
export default FormNotification;
