import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import "./App.css";
import { theme } from "./mantineTheme";

import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      if (error.response?.status === 401) window.location.href = "/logout";
      console.error("Global Error Handler:", error.message);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      if (error.response?.status === 401) window.location.href = "/logout";
      console.error("Global Error Handler:", error.message);
    },
  }),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MantineProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </MantineProvider>
  </React.StrictMode>
);
