export const MSG = {
  TEST: {
    TEXT: "Some Text",
    CHILDREN: "Able to display children text.",
    CLASS: "Able to render className values",
    CONTENT: "Able to display content.",
    HIDE: "Able to hide content.",
    DISABLED: "Able to display disabled property.",
    CLICK: "Able to trigger onClick.",
    CHECKED: "Able to trigger check.",
    UNCHECKED: "Able to trigger uncheck.",
    TYPE: "Able to render type value",
    TYPE_DEFAULT: "Able to render default type value",
    VALUE: "Able to set value.",
    LIST: "Able to display list.",
    FORM: "Able to display form fields.",
    FORM_FILLABLE: "Able to fill up form fields.",
    PEMISSION_INVALID: "Does not accept invalid permission.",
  },
  HELP: {
    NOTIFICATION_THRESHOLD:
      "Set pool balance threshold to control when to receive an email notification. The notification will be sent out to the emails configured below when the pool balance drops below the threshold value.",
    PRODUCT_MODEL_NAME:
      "This is the model number that will appear in Dante Updater. Don’t include the manufacturer name.",
  },
  ROLES: {
    ADMIN:
      "Power user. Ability to see everything in the portal, upload firmware, login to Module Config Tool and manage users; update pool balance notification threshold, add/remove contacts to receive pool balance notifications.",
    FIRMWARE_MANAGER:
      "Ability to create and view self-requested support cases; use Firmware Manager; login to Module Config Tool; view license pool balances, technical documentation (on OEM Home), Your Releases, News & Events, Resources and marketing materials.",
    ENGINEERING:
      "Ability to create and view self-requested support cases; view license pool balances, technical documentation (on OEM Home), Your Releases, News & Events, Resources and marketing materials.",
    LICENSE_MANAGER:
      "Ability to create and view self-requested support cases; view technical documentation (on OEM Home), Your Releases, News & Events, Resources and marketing materials; view license pool balances, update pool balance notification threshold, add/remove contacts to receive pool balance notifications.",
    MARKETING:
      "Ability to create and view self-requested support cases; view News & Events, Resources, marketing materials and submit marketing-related web forms.",
  },
  VALIDATE: {
    REQUIRED: (item) => `${item} is required.`,
    EMAIL: "Invalid email format.",
  },
};
