import { Title } from "@mantine/core";

const ComingSoon: React.FC = () => {
  return (
    <Title order={3} ta="center">
      Coming soon.
    </Title>
  );
};

export default ComingSoon;
