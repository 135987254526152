import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const Home: React.FC = () => {
  return (
    <>
      {Cookies.get("prevLocation") && (
        <Navigate
          to={
            (Cookies.get("prevLocation") != "/" &&
              Cookies.get("prevLocation")) ||
            (Cookies.get("user_permission")
              .split("-")
              .includes("firmwareUpload") &&
              "/firmware") ||
            (Cookies.get("user_permission")
              .split("-")
              .includes("licenseView") &&
              "/license") ||
            (Cookies.get("user_permission")
              .split("-")
              .includes("userManagement") &&
              "/user") ||
            (Cookies.get("user_permission") == "" && "/restricted")
          }
          replace={true}
        />
      )}
    </>
  );
};

export default Home;
