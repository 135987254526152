import { Skeleton as MtSkeleton } from "@mantine/core";

const Skeleton: React.FC = () => {
  return (
    <>
      <MtSkeleton height={8} radius="xl" />
      <MtSkeleton height={8} mt={6} radius="xl" />
      <MtSkeleton height={8} mt={6} width="70%" radius="xl" />
    </>
  );
};

export default Skeleton;
