import Cookies from "js-cookie";
import { useFetchData, usePostData } from "../utils/ReactQueryHooks";
import { isData } from "../utils/HookResponse";

export const useUsers = () =>
  useFetchData({
    key: "users",
    url: "users",
    data: {
      aId: Cookies.get("aId"),
    },
    options: {
      select: (data) => isData(data),
    },
    post: true,
  });

export const useUserOffboard = () => usePostData({ url: "users/offboard" });

export const useUserUpdate = () => usePostData({ url: "users/update" });

export const useUserInvite = () =>
  usePostData({
    url: "users/invite",
    withToastSuccess: false,
    withToastError: false,
  });

export const useUserInviteSend = () =>
  usePostData({
    url: "users/invite/send",
    withToastSuccess: false,
    withToastError: false,
  });

export const useUserAuth0Verify = () =>
  usePostData({
    url: "users/auth0/verify",
    withToastSuccess: false,
    withToastError: false,
  });

export const useUserAuth0Link = () =>
  usePostData({
    url: "users/auth0/link",
    withToastSuccess: false,
    withToastError: false,
  });

export const useUserInviteAccept = (invitationId) =>
  useFetchData({
    key: "inviteAccept",
    url: "invite/accept",
    data: {
      invitationId: invitationId,
    },
    options: {
      enabled: false,
    },
    post: true,
    accessToken: false,
  });
